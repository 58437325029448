<template>
  <lab-task><lab-gas-laws :variation="variation" /></lab-task>
</template>

<script>
import Vue from 'vue';
import LabGasLaws from '../simulations/LabGasLaws';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import LabTask from './labs/LabTask';

export default Vue.extend({
  name: 'ConcordiaGasLaws',
  components: {LabGasLaws, LabTask},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      variation: 1,
    };
  },
});
</script>
