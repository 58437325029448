
























import {computed, defineComponent, PropType} from '@vue/composition-api';

export default defineComponent({
  name: 'StembleLoader',
  props: {
    message: {
      type: String as PropType<string>,
      default: null,
    },
    type: {
      type: String as PropType<'block' | 'inline'>,
    },
    inline: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    spinnerSize: {
      type: [Number, String] as PropType<number | string>,
      default: 32,
    },
    spinnerWidth: {
      type: [Number, String] as PropType<number | string>,
    },
    color: {
      type: String as PropType<string>,
      default: 'secondary',
    },
    textColor: {
      type: String as PropType<string>,
      default: '',
    },
    inlineText: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props) {
    const isBlock = computed(() => {
      return props.type === 'block' || (props.type !== 'inline' && !props.inline);
    });
    return {isBlock};
  },
});
