<script>
import ConcordiaGasLaws from '@/tasks/components/dynamic-questions/ConcordiaGasLaws';

export default ConcordiaGasLaws.extend({
  data() {
    return {
      variation: 0,
    };
  },
});
</script>
