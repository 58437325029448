<template>
  <div v-if="loaded" style="width: 100%; height: 100%">
    <sim-wrapper style="width: 100%; height: 100%">
      <stemble-gas-laws :variation="variation" v-bind="$attrs" v-on="$listeners" />
    </sim-wrapper>
  </div>
  <stemble-loader v-else />
</template>

<script>
import {loadScriptOnce, loadStylesOnce} from '@/loading/utils/dynamic-loading';
import StembleLoader from '@/loading/components/StembleLoader';
import SimWrapper from '@/tasks/components/simulations/SimWrapper';

export default {
  name: 'LabGasLaws',
  components: {StembleLoader, SimWrapper},
  inheritAttrs: false,
  props: {
    variation: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loaded: false,
    };
  },
  mounted() {
    Promise.all([
      loadScriptOnce('/simulations/gas-laws/stemble-gas-laws.js'),
      loadStylesOnce('/simulations/gas-laws/styles.css'),
    ]).then(() => {
      this.loaded = true;
    });
  },
};
</script>
